import React, { useEffect, useState } from 'react';
import { SnackbarFeedback, Icons } from '@paddl/storybook';
import {
  useResource,
  useSubmitData
} from '@paddl/utils-react';

export const XpCollection = () => {
  const [openSnackBar, setOpenSnackBar] = useState();
  const { data: canCollectXpData, isLoading: isCanCollectXpLoading } = useResource(`${process.env.AT_WORK_API}/v2/can-collect-xp/`);
  const { submit: submitCollectXp } = useSubmitData();
  const [collectedXp, setCollectedXp] = useState();

  const XpIcon = Icons.xp;

  useEffect(() => {
    if (isCanCollectXpLoading) return;
    if (canCollectXpData?.canCollectXp) {
      setOpenSnackBar('canCollectCards');
    }
  }, [canCollectXpData?.canCollectXp, isCanCollectXpLoading]);

  const onCollectXp = () => {
    submitCollectXp(`${process.env.AT_WORK_API}/v2/collect-xp/`).then((data) => {
      setCollectedXp(data?.collectedXp);
      setOpenSnackBar('collectedCards');
    });
  };

  return (
    <>
    {openSnackBar === 'canCollectCards' &&
      <SnackbarFeedback
        hasTime={false}
        hasCloseButton={false}
        data-test-id="you-have-xp-available"
        type="infoAlternative"
        isOpen={openSnackBar === 'canCollectCards'}
        action={onCollectXp}
        buttonText="Collect XP ⚡️"
        message={
          <>
            <XpIcon />
            <span>You have XP available!</span>
          </>
        }
      />}
      <SnackbarFeedback
        hasTime
        type="success"
        isOpen={openSnackBar === 'collectedCards'}
        message={
          <span>🎉 You collected {collectedXp} XP!</span>
        }
      />
    </>
  );
};

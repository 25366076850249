import { shape, string } from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import {
  ModularPage
} from '@paddl/storybook';
import {
  ProgramDirectoryModal,
  Metadata,
  ProgramPageContent,
  ProgramPageSidebarContent,
  useResource,
  MemberAppNavigation_Populated
} from '@paddl/utils-react';
import {
  hardRedirect
} from '@paddl/utils-js';

const breadcrumbItems = [
  {
    title: 'Explore',
    action: () => {
      hardRedirect('explore');
    }
  },
  { title: 'Program' }
];

export const ProgramPage = ({
  match: {
    params: { programId }
  }
}) => {
  const [isProfileDirectoryOpen, setIsProfileDirectoryOpen] = useState(false);
  const openProfileDirectory = useCallback(() => {
    setIsProfileDirectoryOpen(true);
  }, []);

  const programUrl = `${process.env.AT_WORK_API}/v2/program/${programId}`;
  const { data: programResponse } =
  useResource(programUrl);

  const program = programResponse?.program;

  useEffect(() => {
    if (window?.analytics && programResponse?.program) {
      window.analytics.page(
        'Explore App',
        'Program',
        {
          programId,
          programTitle: programResponse.program.title,
          providerId: programResponse.program.providerId
        });
    }
  }, [programId, programResponse?.program]);

  return <div>
  <Metadata title={`Program ${program?.title || ''} | Paddl`} />
  <ModularPage
    breadcrumbItems={breadcrumbItems}
    navigationContent={<MemberAppNavigation_Populated currentPage="explore" />}
    pageName="Experience"
    pageContent={program ? <ProgramPageContent
      program={program}
      openProfileDirectory={openProfileDirectory}
      breadcrumbItems={breadcrumbItems}
      pageBackground="dark"
    /> : null}
    sidebarContent={program ? <ProgramPageSidebarContent
      program={program}
      openProfileDirectory={openProfileDirectory}
    /> : null}
  />
  {isProfileDirectoryOpen && <ProgramDirectoryModal
    isModalOpen={isProfileDirectoryOpen}
    programId={programId}
    onClose={() => setIsProfileDirectoryOpen(false)}
  />}
         </div>;
};

ProgramPage.propTypes = {
  match: shape({
    params: shape({
      programId: string.isRequired
    }).isRequired
  }).isRequired
};

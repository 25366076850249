import {
  ModularPage
} from '@paddl/storybook';
import {
  useCompleteActivity,
  useResource,
  ExperiencePageContentWrapper,
  ExperienceSidebarContent,
  MemberAppNavigation_Populated
} from '@paddl/utils-react';
import { shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import {
  hardRedirect
} from '@paddl/utils-js';

export const ExperiencePage = ({
  match: {
    params: { experienceId, programId }
  }
}) => {
  const { data, isLoading } = useResource(`${process.env.AT_WORK_API}/v2/experience/${experienceId}`);
  const completeActivityData = useCompleteActivity({ experienceId });

  const breadcrumbItems = [
    {
      title: 'Explore',
      action: () => {
        hardRedirect('explore');
      }
    }];

  if (programId) {
    breadcrumbItems.push({
      title: 'Program',
      action: () => hardRedirect('exploreProgram', { programId })
    });
  }
  breadcrumbItems.push({
    title: 'Experience'
  });

  useEffect(() => {
    if (window?.analytics && data?.experience) {
      window.analytics.page(
        'Explore App',
        'Experience',
        { experienceId, experienceTitle: data.experience.title, activityType: data.experience.activityType });
    }
  }, [data, experienceId]);

  return (

    <ModularPage
      breadcrumbItems={breadcrumbItems}
      navigationContent={<MemberAppNavigation_Populated currentPage="explore" />}
      pageName="Experience"
      pageContent={<ExperiencePageContentWrapper experience={data?.experience} isLoading={isLoading} completeActivityData={completeActivityData} breadcrumbItems={breadcrumbItems} />}
      sidebarContent={<ExperienceSidebarContent
        experience={data?.experience}
        isComplete={completeActivityData.isComplete}
        activity={completeActivityData.activity}
      />}
    />
  );
};

ExperiencePage.propTypes = {
  match: shape({
    params: shape({
      experienceId: string.isRequire,
      programId: string
    }).isRequired
  }).isRequired
};
